<template>
    <Hero/>
    <Container/>
</template>

<script>
import Container from '../components/Container.vue'
import Hero from '../components/Hero.vue'

export default {
    components:{
        Hero,
        Container,
 
    }
}
</script>
        Containerscript>

<style>

</style>