<template>
<div class="hero">
    <div class="container">
        <h2>Blog Name</h2>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.hero{

    height: 40vh;
    background-color:#151515;
    .container{
        position: relative;
        top: 40%;
        text-align: center;
        h2{
            color: #fff;
            font-size: 4rem;
        }
    }
}


</style>