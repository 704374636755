<template>
  <footer>
      <p>Made with <span>♥</span>  by Aswin</p>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

footer{
    background-color:blanchedalmond;
    height: 5vh;
    padding-top: 20px;
    text-align: center;
    width:100%;
    span{
      color: red;
    }
}

</style>